$textColor: #37474F;
$GreyColor: #8F8C8C;
$GreyLite: #E3E3E3;
$GreenColor: #128d54;
$RedColor:#E24C4B;
$RedLite: #F8F0F6;
$BlueColor: #A2C8FC;
$BlueLite: #FBFAFD;
$OrangeColor: #FC8456;
$YellowLite: #FEF6EE;
$OpacityColor: rgba(0,0,0,0.57);

