@import './../scss/Colors.scss';
.businesPopap{
    position: relative;
    font-family: 'Poppins', sans-serif;
}
.businesPopap__block{
    display: flex;
    cursor: pointer;
}
.businesPopap__item{
    width: 100%;
    text-align: center;
    color: $textColor;
}
.businesPopap__Block{
    display: none;
    &-active{
        min-width: 360px;
        transform: translateX(-50%);
        top: 40px;
        left: 50%;
        position: absolute;
        background: white;
        border-radius: 5px;
        box-shadow: 0px 0px 6px #7583954D;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 20px;
        box-sizing: border-box;
        z-index: 1;
    }
}
.businesPopap__img{
    width: 8.5px;
    height: 3.7px;
    margin: 12px 15px 0 8px;
}
.menu-blok__margin{
    .businesPopap__img{
        margin: 12px 0px 0 8px;
    }
}
.businesPopap{
    cursor: pointer;
}