@import './../scss/Colors.scss';
.servicesSlider{
    display: flex;
    justify-content: flex-start;
}
.servicesSliderItem{
    min-height: 440px;
    // border: 2px solid red;
    background: white;
    border-radius: 5px;
    box-shadow: 0px 0px 6px #A2C8FC4D;
    &__text{
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        padding: 21px 23px;
        box-sizing: border-box;
        height: 248px;
    }
    &__img{
        height: 192px;
        object-fit: cover;
        border-radius: 5px 5px 0 0;
    }
    &__title{
        color: $GreenColor;
        font-family: 'Poppins', sans-serif;
        font-size: 20px;
    }
    &__description{
        color: $textColor;
        font-family: 'Poppins', sans-serif;
    }
    
}