@import './scss/Colors.scss';

body,h1,h2,h3,p {
    margin: 0;
}
a{
  text-decoration: none;
}
.wrapper{
  max-width: 1500px;
  width: calc(100% - 30px);
  margin: 0 auto;
}
.wrapperR{
  max-width: 1300px;
  width: calc(100% - 60px);
  margin: 0 auto;
}

html{
  touch-action: manipulation;
  content-visibility: auto;
}

.test{
  background: red;
}
.webBTN{
  min-width: 170px;
  padding: 10px 18px;
  box-sizing: border-box;
  background: $GreenColor;
  color: white;
  text-align: center;
  font-family: 'Poppins', sans-serif;
  cursor: pointer;
  border: none;
  border-radius: 6px;
  font-size: 16px;
  font-weight: 600;
  @media(max-width: 550px){
    min-width: 120px;
    padding: 6px 9px;
    box-sizing: border-box;
    font-size: 14px;
  }
}
.title__Text{
  text-align: center;
  color: $textColor;
  font-family: 'Poppins', sans-serif;
}
.title__line{
  width: 142px;
  height: 5px;
  background: $GreenColor;
  display: block;
  margin: 5px auto 0;
  border-radius: 4px;
}

.banerR__img{
  width: 80%;
  max-width: 810px;
  position: absolute;
  height: auto;
  top: 0;
  right: 0;
  // background-blend-mode: screen;
  @media (max-width: 800px) {
      filter: opacity(70%);
  }
}

.Learn_More__link{
  color: $OrangeColor;
  font-family: 'Poppins', sans-serif;
  font-size: 18px;
}

.test1{
  max-width: 750px;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: -1;
  @media(max-width: 550px){
     width: 70%;
  }
}
.menu-blok__margin{
  @media (max-width: 1250px){
    margin-bottom: 10px;
  }
}

.visually-hidden {
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}