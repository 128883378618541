@import './../scss/Colors.scss';
.Packeges{
    display: flex;
    flex-direction: column;
    position: relative;
    &__Cards{
        display: flex;
        justify-content: space-around;
        align-items: flex-end;
        flex-wrap: wrap;
        width: calc(100% - 30px);
        max-width: 1500px;
        margin: 0 auto;
        margin-top: 60px;
        margin-bottom: -150px;
        z-index: 1;

        
        @media(max-width:1213px){
           flex-direction: column;
           align-items: center;
        }
    }
    &__radio{
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 25px;
        margin: 0;
        // background: #000;
    }
    &__BG{
        height: 200px;
        background: $YellowLite;
    }

    .SCope &__Cards{
        margin-bottom: -300px;
    }
    .SCope &__BG{
        height: 400px;
        background: $BlueColor;
    }

    .LLC_Cope &__Cards{
        margin-bottom: -300px;
    }
    .LLC_Cope &__BG{
        height: 400px;
        background:  $YellowLite;
    }

    &__item{
        height: 564px;
        width: 359px;
        // min-width: 359px;
        border-radius: 5px;
        background: white;
        box-shadow: 0px 0px 6px #7583954D;
        margin: 5px;
        position: relative;
        @media(max-width:1213px){
            margin-bottom: 51px;
            height: auto;
            width: 416px;
            padding-bottom: 80px;
            box-sizing: border-box;
            @media(max-width:550px){
                width: calc(100% - 30px);
            }
        }
        &_Corp{
            height: 610px;
            @media(max-width:1213px){
                height: auto;
                padding-bottom: 80px;
                box-sizing: border-box;
                @media(max-width:550px){
                    width: calc(100% - 30px);
                }
            }
        }
    }
    .Packeges__center{
        height: 650px;
        width: 416px;
        @media(max-width:1213px){
            order: -1;
            height: auto;
            padding-bottom: 80px;
            box-sizing: border-box;
        }
        @media(max-width:550px){
            width: calc(100% - 30px);
        }
        &_Corp{
            height: 675px;
            @media(max-width:1213px){
                height: auto;
                padding-bottom: 80px;
                box-sizing: border-box;
                @media(max-width:550px){
                    width: calc(100% - 30px);
                }
            }
        }
    }
    &__text{
        text-align: center;
        padding: 3px 20px;
        box-sizing: border-box;
        color: $textColor;
        font-family: 'Poppins', sans-serif;
        &__list{
            padding: 0 12px;
            box-sizing: border-box;
            position: relative;
            color: $textColor;
            font-family: 'Poppins', sans-serif;
            text-align: center;
            font-size: 14px;
            &::before{
                content: "";
                width: 4px;
                height: 4px;
                border-radius: 50%;
                background: $GreenColor;
                position: absolute;
                top: 8px;
                margin-left: -8px;
                // transform: translateY(-50%);
            }
        }
    }
    &__price{
        text-align: center;
        margin: 18px 20px 0;
        // box-sizing: border-box;
        font-size: 30px;
        color: $textColor;
        font-family: 'Poppins', sans-serif;
        position: relative;
        &::before{
            content: "+ state fees";
            font-size: 16px;
            color: $textColor;
            position: absolute;
            top: 50%;
            left: 62%;
            transform: translateY(-50%);
        }
        // margin-top: 18px;
    }
    &__Title1{
        text-align: center;
        color: white;
        font-family: 'Poppins', sans-serif;
        padding: 21px 0;
        box-sizing: border-box;
        text-transform: uppercase;
        position: relative;
        background: $BlueColor;
        border-radius: 5px 5px 0 0;
    }
    &__SubTitle1{
        color: $BlueColor;
        font-family: 'Poppins', sans-serif;
        text-align: center;
        padding: 13px 20px;
        box-sizing: border-box;
        font-size: 18px;
    }
    &__Title2{
        text-align: center;
        color: white;
        font-family: 'Poppins', sans-serif;
        padding: 21px 0;
        box-sizing: border-box;
        text-transform: uppercase;
        position: relative;
        background: $GreenColor;
        border-radius: 5px 5px 0 0;
    }
    &__SubTitle2{
        color: $GreenColor;
        font-family: 'Poppins', sans-serif;
        text-align: center;
        padding: 13px 20px;
        box-sizing: border-box;
        font-size: 18px;
    }
    &__Title3{
        text-align: center;
        color: white;
        font-family: 'Poppins', sans-serif;
        padding: 21px 0;
        box-sizing: border-box;
        text-transform: uppercase;
        position: relative;
        background: $OrangeColor;
        border-radius: 5px 5px 0 0;
    }
    &__SubTitle3{
        color: $OrangeColor;
        font-family: 'Poppins', sans-serif;
        text-align: center;
        padding: 13px 20px;
        box-sizing: border-box;
        font-size: 18px;
    }
    &__link1{
        position: absolute;
        bottom: 20px;
        color: white;
        background: $BlueColor;
        font-family: 'Poppins', sans-serif;
        padding: 8px 21px;
        box-sizing: border-box;
        border-radius: 5px;
        cursor: pointer;
        font-weight: 600;
        left: 50%;
        transform: translateX(-50%);
        @media(max-width: 550px){
            width: 60%;
            text-align: center;
        }
    }
    &__link2{
        position: absolute;
        bottom: 20px;
        color: white;
        background: $GreenColor;
        font-family: 'Poppins', sans-serif;
        padding: 8px 21px;
        box-sizing: border-box;
        border-radius: 5px;
        cursor: pointer;
        font-weight: 600;
        left: 50%;
        transform: translateX(-50%);
        @media(max-width: 550px){
            width: 60%;
            text-align: center;
        }
    }
    &__link3{
        position: absolute;
        bottom: 20px;
        color: white;
        background: $OrangeColor;
        font-family: 'Poppins', sans-serif;
        padding: 8px 21px;
        box-sizing: border-box;
        border-radius: 5px;
        cursor: pointer;
        font-weight: 600;
        left: 50%;
        transform: translateX(-50%);
        @media(max-width: 550px){
            width: 60%;
            text-align: center;
        }
    }
}


// .checkmarkR {
//     position: absolute;
//     top: 0;
//     left: 0;
//     height: 25px;
//     width: 25px;
//     opacity: 0.5;
//     background-color: #eee;
//     position: absolute;
//     top: 50%;
//     left: 20px;
//     transform: translateY(-50%);
// }
.Packeges__Cards .Packeges__radio:checked ~ .checkmarkR {
    opacity: 1;
  }


  .containerR {
    display: block;
    position: relative;
    padding-left: 35px;
    box-sizing: border-box;
    left: 20px;
    top: 25px;
    margin-bottom: 12px;
    cursor: pointer;
    position: absolute;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  
  /* Hide the browser's default radio button */
  .containerR input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }
  
  /* Create a custom radio button */
  .checkmarkR {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: #eee;
    // border-radius: 50%;
  }
  
  /* On mouse-over, add a grey background color */
  .containerR:hover input ~ .checkmarkR {
    background-color: #ccc;
  }
  
  /* When the radio button is checked, add a blue background */
  .containerR input:checked ~ .checkmarkR {
    background-position: center;
    background-size: contain;
    background-image: url('./../assets/images/tick.png');
  }
  
  /* Create the indicator (the dot/circle - hidden when not checked) */
  .checkmarkR:after {
    content: "";
    position: absolute;
    display: none;
  }
  
  /* Show the indicator (dot/circle) when checked */
  .containerR input:checked ~ .checkmarkR:after {
    display: block;
  }
  
  /* Style the indicator (dot/circle) */
  .containerR .checkmarkR:after {
       top: 9px;
      left: 9px;
      width: 8px;
      height: 8px;
    //   border-radius: 50%;
    //   background: white;
  }