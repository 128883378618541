@import './../scss/Colors.scss';

.LiveBg{
    position: absolute;
    top: 0;
}

.homeBaner{
    width: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
    // flex-direction: column;
    max-width: 1400px;
    width: calc(100% - 30px);
    margin: 0 auto;
    @media(max-width:1050px){
        flex-direction: column;
        align-items: flex-start;
    }
    &__content{
        padding: 130px 0;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        @media (max-width: 1200px) {
            padding: 160px 0;
        }
        @media (max-width: 1050px) {
            padding: 160px 0 60px;
        }
        @media (max-width: 800px) {
            padding: 60px 0 60px;
        }
        
        &__block{
            width: 520px;
            display: flex;
            align-items: center;
            margin-top: 31px;
            @media (max-width: 550px) {
               flex-direction: column;
               width: 100%;
            }
        }
        &__title{
            color: $textColor;
            font-family: 'Poppins', sans-serif;
            font-size: 50px;
            max-width: 502px;
            @media (max-width: 800px) {
                font-size: 30px;
            }
        }
        &__subtitle{
            color: $textColor;
            font-family: 'Poppins', sans-serif;
            font-size: 35px;
            @media (max-width: 800px) {
                font-size: 20px;
            }
        }
        &_text{
            max-width: 360px;
            color: $GreenColor;
            font-family: 'Poppins', sans-serif;
            font-size: 25px;
            @media (max-width: 800px) {
                font-size: 20px;
            }
        }
        &__read{
            color: $GreenColor;
            padding: 0 20px 10px 20px;
            box-sizing: border-box;
            font-family: 'Poppins', sans-serif;
            position: relative;
            cursor: pointer;
            // border: 1px solid red;
            &::before{
                content: '>';
                position: absolute;
                bottom: -5px;
                right: 50%;
                transform: rotate(90deg);
            }
        }
        &__select{
            background: white;
            border: 1px solid $GreenColor;
            background: white;
            height: 41px;
            padding: 11px;
            box-sizing: border-box;
            font-family: 'Poppins', sans-serif;
            border-radius: 6px;
            min-width: 243px;
            outline: none;
            margin-right: 21px;
            @media (max-width: 550px) {
                margin-right: 0px;
                margin-top: 10px;
                width: 100%;
            }
        }
    }
    &__Learn_more{
        margin-bottom: 130px;
        margin-left: 80px;
        @media (max-width: 1050px) {
            margin-left: 0;
        }
        &__title{
            font-family: 'Poppins', sans-serif;
            color: $textColor;
            font-size: 25px;
            font-weight: 400;
            @media (max-width: 550px) {
                text-align: center;
            }
        }
        &__links{
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            width: 360px;
            @media (max-width: 550px) {
                width: 100%;
            }
        }
        &__link{
            width: calc(50% - 5px);
            padding: 5px 11px;
            box-sizing: border-box;
            text-align: center;
            background: #D6F3E8;
            font-family: 'Poppins', sans-serif;
            display: flex;
            justify-content: center;
            align-items: center;
            color: #667C87;
            font-weight: 600;
            border-radius: 6px;
            margin-top: 10px;
            @media (max-width: 550px) {
                width: 100%;
                
            }
        }
    }
    &__seacrchINP{
        border: 1px solid $GreenColor;
        background: white;
        // color: $GreenColor;
        // font-weight: 600;
        font-family: 'Poppins', sans-serif;
        padding: 11px;
        box-sizing: border-box;
        border-radius: 6px;
        // min-width: 170px;
        outline: none;
        &::placeholder{
            color: $GreenColor;
            text-align: center;
            font-weight: 600;
            font-size: 15px;
        }
        // text-align: center;
        // @media (max-width: 550px) {
        //     flex-direction: column;
        //     // align-items: flex-start;
        // }
    }
}
.homeBaner__OR{
    color: $textColor;
    font-family: 'Poppins', sans-serif;
    margin: 0 21px;
}