@import './../scss/Colors.scss';
.LoginModal{
    width: 100%;
    height: 100vh;
    background: $OpacityColor;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
    &__none{
        display: none;
    }
}

.loginForm{
    width: 458px;
    background: white;
    top: 100px;
    left: 50%;
    position: relative;
    transform: translateX( -50%);
    border-radius: 10px;
    &__container{
        padding: 53px 62px 20px;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
    }
    .login__inp{
        width: 100%;
        margin-bottom: 20px;
        border: 1px solid #BFBFBF;
        color: $textColor;
        font-family: 'Poppins', sans-serif;
        border-radius: 5px;
        padding: 13px 20px;
        box-sizing: border-box;
        outline: none;
    }
    .login__title{
        color: $textColor;
        font-family: 'Poppins', sans-serif;
        font-size: 20px;
        text-align: center;
        margin-bottom: 40px;
        &__greenWord{
            color: $GreenColor;
        }
    }
    .login__BTN{
        background: $GreenColor;
        font-size: 16px;
        color: white;
        font-weight: 600;
        font-family: 'Poppins', sans-serif;
        border-radius: 5px;
        border: none;
        padding: 12px 0;
        box-sizing: border-box;
        cursor: pointer;
        outline: none;
    }
    &__join{
        border-top:  1px solid #BFBFBF;
        padding: 10px 15px 25px;
        box-sizing: border-box;
        &__text{
            text-align: center;
            color: $textColor;
            font-family: 'Poppins', sans-serif;
        }
    }
}