@import './Colors.scss';

.frequently{
    padding-top: 60px;
    box-sizing: border-box;
}
.frequently__DropDown{
    width: calc(100% - 30px);
    max-width: 1255px;
    margin: 0 auto;
    margin-top: 60px;
}
.frequently__details{
    // height: 95px;
    // border-radius: 5px;
    // border: 1px solid $GreenColor
    margin-bottom: 36px;
    position: relative;
    &[open] .frequently__summary{
        background: $GreenColor;
        color: white;
        font-family: 'Poppins', sans-serif;
        border-radius: 5px 5px 0 0;
    }
    @media (max-width:800px) {
        margin-bottom: 15px;
    }
}
.frequently__summary{
    // height: 95px;
    font-size: 25px;
    font-weight: 600;
    color: $textColor;
    font-family: 'Poppins', sans-serif;
    padding: 27px 30px 27px 109px;
    box-sizing: border-box;
    border-radius: 5px;
    border: 1px solid $GreenColor;
    cursor: pointer;
    &::marker {
        content: '';
    }
    &::before {
        content: "+";
        font-size: 30px;
        margin-left: -30px;
        margin-top: -6px;
        position: absolute;
        right: 18px;
    }
    @media (max-width:800px) {
        font-size: 18px;
        padding: 14px 35px 15px;
    }
}
.frequently__text{
    padding: 31px 50px 50px;
    box-sizing: border-box;
    color: $textColor;
    border-radius:0 0 5px 5px;
    background: $YellowLite;
    font-family: 'Poppins', sans-serif;
    @media (max-width:800px) {
        padding: 14px 35px 15px;
    }
}