@import './Colors.scss';

.footer{
    border-top: 1px solid #E3E3E3;
    padding-top: 100px;
    padding-bottom: 66px;
    box-sizing: border-box;
    &__container{
        display: flex;
        flex-wrap: wrap;
    }
    &__text{
        color: $textColor;
        font-family: 'Poppins', sans-serif;
        margin-bottom: 11px;
    }
    &__Entites{
        width: 25%;
        display: flex;
        flex-direction: column;
        @media (max-width: 1050px) {
            width: 50%;
        }
        @media (max-width: 650px) {
            width: 100%;
            margin-top: 30px;
        }
        &_block{
            margin-top: 11px;
        }
        &__Links{
            width: 25%;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            @media (max-width: 1050px) {
                width: 50%;
            }
            @media (max-width: 650px) {
                width: 100%;
            }
        }
    }
    &__Services{
        width: 50%;
        @media (max-width: 1050px) {
            width: 50%;
            order: -1;
        }
        @media (max-width: 650px) {
            width: 100%;
        }
    }
    &__FollowUs{
        width: 25%;
        @media (max-width: 1050px) {
            width: 50%;
        }
        @media (max-width: 650px) {
            width: 100%;
            margin-top: 30px;
        }
    }
    &__Items{
        .footer__Item{
            width: 42px;
            height: 42px;
            margin-right: 25px;
            cursor: pointer;
            transition: .33s;
            &:hover{
                transform: scale(1.2);
            }
        }
    }
    &__Link{
        color: $textColor;
        font-family: 'Poppins', sans-serif;
        margin-top: 11px;
        // font-size: 16px;
    }
    &__title{
        font-size: 25px;
        color: $textColor;
        padding-right: 10px;
        box-sizing: border-box;
        margin-bottom: 16px;
        font-family: 'Poppins', sans-serif;
    }
}