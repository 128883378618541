@import './../scss/Colors.scss';
.baner{
    width: 100%;
    &__content{
        max-width: 1400px;
        width: calc(100% - 30px);
        padding: 143px 0 200px 0;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        margin: 0 auto;
        // @media (max-width: 1200px) {
        //     padding: 160px 0;
        // }
        @media (max-width: 800px) {
            padding: 60px 0;
        }
        &__block{
            display: flex;
            align-items: center;
            margin-top: 31px;
        }
        &__title{
            color: $textColor;
            font-family: 'Poppins', sans-serif;
            font-size: 50px;
            @media (max-width: 800px) {
                font-size: 30px;
            }
        }
        &__subtitle{
            color: $textColor;
            font-family: 'Poppins', sans-serif;
            font-size: 35px;
            @media (max-width: 800px) {
                font-size: 20px;
            }
        }
        &_subtext{
            max-width: 360px;
            color: $GreenColor;
            font-family: 'Poppins', sans-serif;
            font-size: 25px;
            @media (max-width: 800px) {
                font-size: 20px;
            }
        }
        &_text{
            color: $GreyColor;
            font-family: 'Poppins', sans-serif;
            font-size: 25px;
            @media (max-width: 800px) {
                font-size: 16px;
            }
        }
        &__read{
            color: $GreenColor;
            padding: 0 20px 10px 20px;
            box-sizing: border-box;
            font-family: 'Poppins', sans-serif;
            position: relative;
            cursor: pointer;
            // border: 1px solid red;
            &::before{
                content: '>';
                position: absolute;
                bottom: -5px;
                right: 50%;
                transform: rotate(90deg);
            }
        }
    }
}
.baner__img{
    width: 80%;
    max-width: 1050px;
    position: absolute;
    height: auto;
    top: 0;
    right: 0;
    z-index: -1;
    @media (max-width: 800px) {
        filter: opacity(70%);
        top: 80px;
    }
}
.baner__img2{
    width: 30%;
    // max-width: 1050px;
    position: absolute;
    height: auto;
    bottom:  0;
    left:  0;
    z-index: -1;
}