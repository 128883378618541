@import './../scss/Colors.scss';
.corp{
    padding-bottom:  90px;
    box-sizing: border-box;
    &-title_block{
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: 60px;
        margin-bottom: 60px;
    }
    &-title{
        text-align: center;
        color: $textColor;
        font-family: 'Poppins', sans-serif;
    }
    &-subTitle{
        text-align: center;
        color: $GreyColor;
        font-family: 'Poppins', sans-serif;
        font-size: 20px;
    }
}
.corp__card__block{
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
}
.corp__card{
    width: calc(100% - 30px);
    max-width: 416px;
    height: 473px;
    margin: 5px 5px 30px 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 70px 40px 80px;
    border-radius: 5px;
    box-sizing: border-box;
    &__text{
        color: $textColor;
        font-family: 'Poppins', sans-serif;
        text-align: center;
    }
    &__title{
        color: $textColor;
        font-family: 'Poppins', sans-serif;
        // text-align: center;
    }
    &_img{
        width: 72px;
        height: 72px;
        object-fit: cover;
    }
    &__BTN{
        background: $GreenColor;
        padding: 9.5px 22px;
        box-sizing: border-box;
        border: none;
        border-radius: 4px;
        font-size: 16px;
        color: white;
        font-family: 'Poppins', sans-serif;
        font-weight: 600;
    }
}
.card1{
    background: $BlueLite;
}
.card2{
    background: #F8F0F6;
}
.card3{
    background: $YellowLite;
}