@import './../scss/Colors.scss';

.S_CopeForm{
    background: $BlueLite;
    padding-top: 50px;
    box-sizing: border-box;
    &_block{
        max-width: 645px;
        width: calc(100% - 30px);
        // margin: 0 auto;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        z-index: 2;
    }
    &__container{
        display: flex;
        justify-content: flex-start;
        max-width: 1200px;
        width: calc(100% - 30px);
        margin: 0 auto;
        margin-top: 120px;
        padding-bottom: 175px;
        box-sizing: border-box;
        position: relative;
    }
    &__inp__container{
        width: calc(50% - 11.5px);
        @media (max-width: 600px){
            width: 100%;
        }
        &Business{
            width: 100%;
        }
    }
    &__inp{
        width: 100%;
        margin-bottom: 20px;
        height: 48px;
        padding: 0 25px;
        box-sizing: border-box;
        border: 1px solid $GreenColor;
        border-radius: 5px;
        outline: none;
        &[type=number] {
            -moz-appearance: textfield;
          }
        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }
    }
}
.S_formImg{
    height: 350px;
    right: 0;
    top: -70px;
    position: absolute;
}