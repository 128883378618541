.incorporate{
    width: calc(100% - 30px);
    max-width: 1500px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    overflow: hidden;
    &__Container{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        height: 100px;
    }
    &__Box{
        position: relative;
        
    }
}
.Path{
    width: 70%;
    max-width: 785px;
    height: auto;
    position: absolute;
    z-index: -1;
    right: 0;
    bottom: 0;
}
.incorporateImg{
    width: 50%;
    max-width: 800px;
    margin-bottom: 100px;
}