@import './../scss/Colors.scss';
.CorpVS{
    background: $YellowLite;
    padding-top: 60px;
    padding-bottom: 40px;
    box-sizing: border-box;
    &__title__Text{
        color: $textColor;
        font-family: 'Poppins', sans-serif;
        font-size: 50px;
        text-align: center;
    }
    &__block{
        display: flex;
        justify-content: space-between;
        width: calc(100% - 30px);
        max-width: 1300px;
        margin: 0 auto;
        @media (max-width: 760px) {
            flex-direction: column;
        }
        &__column{
            display: flex;
            flex-direction: column;
            max-width: 527px;
            width: calc(50% - 10px);
            @media (max-width: 760px) {
                width: 100%;
                max-width: 760px;
            }
        }
        &__item{
            width: 100%;
            border-radius: 10px;
            background: white;
            padding: 34px;
            box-sizing: border-box;
            margin-bottom: 20px;
        }
        &__title{
            margin-bottom: 18px;
            color: $textColor;
            font-family: 'Poppins', sans-serif;
            font-size: 18px;
        }
        &__description{
            color: $textColor;
            font-family: 'Poppins', sans-serif;
            font-size: 16px;
        }
    }
}
.CorpVS__title{
    max-width: 1300px;
    width: 100%;
    margin: 0 auto;
    
    justify-content: space-between;
    align-items: center;
    padding: 92px 0;
    box-sizing: border-box;
    display: none;
    @media (min-width: 760px) {
        display: flex;
    }
    
    &Adv{
        color: $textColor;
        max-width: 527px;
        width: calc(50% - 10px);
        font-family: 'Poppins', sans-serif;
        font-size: 25px;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    &Vs{
        color: $textColor;
        text-align: center;
        font-family: 'Poppins', sans-serif;
        font-size: 25px;
    }
    &Dis{
        color: $textColor;
        max-width: 527px;
        width: calc(50% - 10px);
        font-family: 'Poppins', sans-serif;
        font-size: 25px;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    &-mobile{
        display: block;
        max-width: 100%;
        width: 100%;
        @media (min-width: 760px) {
            display: none;
        }
    }
}
.advantages_item{
    border-top: 9px solid $GreenColor;
}
.disadvantages_item{
    border-top: 9px solid $RedColor;
}

.LLC_Cope .CorpVS{
    background: $BlueLite;
}
.vsIcon{
    width: 40px;
    height: 40px;
    object-fit: cover;
    margin-right: 10px;
}