@import './../scss/Colors.scss';
.menu{
    width: 100%;
    height: 78px;
    background: white;
    -webkit-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.3);
    -moz-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.3);
    box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.3);
    display: flex;
    align-items: center;
    position: sticky;
    top: 0;
    z-index: 10;
    right: 0;
    &-container{
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: calc(100% - 30px);
        max-width: 1500px;
        margin: 0 auto;
        padding: 20px;
        box-sizing: border-box;
        @media(max-width: 550px){
            padding: 20px 0;
        }
    }
    &__dashboard{
        display: flex;
        align-items: center;
    }
    &__navigation{
        display: flex;
        margin-right: 15px;
        @media (max-width: 1250px) {
            display: none;
        }
    }
    &__login{
        cursor: pointer;
        font-weight: 600;
        margin: 0 20px;
        font-family: 'Poppins', sans-serif;
        @media(max-width: 550px){
            margin: 0 10px;
        }
    }
    &__Tel{
        color: $GreenColor;
        font-weight: 600;
        font-family: 'Poppins', sans-serif;
        &__dasctop{
            @media (max-width: 1250px) {
                display: none;
            }
        }
    }
    &__logo{
        color: $GreenColor;
        font-size: 25px;
        font-family: 'Poppins', sans-serif;
        @media (max-width: 1250px) {
            font-size: 18px;
        }
        span{
            color: $textColor;
        }
    }
    &-grup{
        display: flex;
        align-items: center;
        // justify-content: space-between;
    }
}
.hamburger-mb-menu{
    display: none;
    @media (max-width: 1250px) {
        display: block;
        width: 50px;
        height: 25px;
        margin-left: 10px;
        position: relative;
        cursor: pointer;
    }
    .hamburger1, .hamburger2, .hamburger3 , .hamburger11, .hamburger22, .hamburger33{
        display: block;
        position: absolute;
        height: 4px;
        width: 100%;
        background: black;
        border-radius: 9px;
        opacity: 1;
        left: 0;
        transition: .25s ease-in-out;
    }
    
    .hamburger1 {
        top: 0px;
        transform-origin: center center;
    }

    .hamburger2 {
      top: 10px;
      transform-origin: center center;
    }

    .hamburger3 {
      top: 20px;
      transform-origin: center center;
    }

    .hamburger11 {
      -webkit-transform: rotate(45deg);
      -moz-transform: rotate(45deg);
      -o-transform: rotate(45deg);
      transform: rotate(45deg);
      top: 12px;
    }

    .hamburger22 {
      width: 0%;
      opacity: 0;
    }

    .hamburger33 {
      -webkit-transform: rotate(-45deg);
      -moz-transform: rotate(-45deg);
      -o-transform: rotate(-45deg);
      transform: rotate(-45deg);
      top: 12px;
    }
}

.menu-blok{
    display: none;
    &-active{
        top: 0;
        left: 0;
        width: 100%;
        position: absolute;
        margin-top: 78px;
        height: calc(100vh - 78px);
        background: rgba(0,0,0,0.3);
        display: flex;
        justify-content: flex-end;
        @media (min-width: 1250px) {
            display: none;
        }
    }
    &__navigation{
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 20px;
        box-sizing: border-box;
    }
    &__container{
        width: 400px;
        margin-right: -400px;
        background: white;
        // padding-bottom: ;
        animation-name: hamburgerMenu;
        animation-duration: 1s;
        animation-delay: .2s;
        animation-timing-function: ease;
        animation-fill-mode: forwards;
        // display: flex;
        // justify-content: space-between;
        // flex-direction: column;
        // align-items: center;
        @keyframes hamburgerMenu {
            from {margin-right: -400px;}
            to {margin-right: 0px;}
        }
    }
}
.searchInp__Container{
    display: flex;
    align-items: center;
    margin-right: 0;
    display: none;
    @media(min-width:750px){
        display: flex !important ;
    }

    &__mobile{
        display: flex !important;
        margin-left: 15px;
        width: calc(100% - 30px);
        left: 0;
        position: absolute;
        top: 90px;
        @media(min-width:750px){
            width: auto;
            border-radius: 4px 0 0 4px;
            top: 0;
            position: relative;
        }
        @media(min-width:550px){
            width: calc(100% - 60px);
            margin-left: 30px;   
        }
    }
}
.searchInp__container{
    // max-width: calc(100% - 60px);
    width: 100%;
    @media (min-width: 750px) {
        max-width: 170px;
    }
}
.searchInp{
    height: 30px;
    outline: none;
    margin: 0;
    padding: 0 10px;
    box-sizing: border-box;
    border-radius: 5px 0 0 5px;
    border: 1px solid #DCDCDC;
    width: 100%;
    // max-width: calc(100% - 60px);
    font-weight: 700;
    @media (min-width: 750px) {
        max-width: 170px;
    }
        
    &Btn{
        width: 76px;
        height: 30px;
        border: none;
        margin-right: 0;
        
        border-radius: 0 5px 5px 0;
        outline: none;
        // margin-right: 15px;
        background: $GreenColor;
        cursor: pointer;
        color: white;
        font-weight: 600;
        font-family: 'Poppins', sans-serif;
        // display: none;
        // @media(min-width:750px){
        //     display: block !important;
        //     // display: none;
        // }
        @media (min-width: 1250px) {
           margin-right: 15px;
        //    border-radius: 5px;
        }
        @media (max-width: 750px) {
            // margin-right: 0;
            border-radius: 0 5px 5px 0;
         }
    }
}

.incorporateBtn{
    @media (max-width: 1250px) {
        display: none;
    }
}
.searchBtn{
    background: $GreenColor;
    border: none;
    height: 30px;
    border-radius: 6px;
    display: block;
    cursor: pointer;
    @media(min-width: 750px){
        display: none;
    }
}
.searchIcon{
    width: 22px;
    height: 22px;
    object-fit: cover;
}