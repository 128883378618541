@import './../scss/Colors.scss';
.services{
    background: $YellowLite;
    padding-top: 60px;
    box-sizing: border-box;
    &__slider{
        padding-top: 78px;
        padding-bottom: 141px;
        box-sizing: border-box;
        width: calc(100% - 30px);
        max-width: 1600px;
        margin: 0 auto;
    }
   
}