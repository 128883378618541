@import './../scss/Colors.scss';
.BusinessRegistration__banerR{
    padding-top: 325px;
    box-sizing: border-box;
    &__title{
        max-width: 1300px;
        left: 50%;
        transform: translateX(-50%);
        font-size: 50px;
        color: $textColor;
        font-family: 'Poppins', sans-serif;
        margin-bottom: 34px;
        top: 180px;
        z-index: 1;
        position: absolute;
    }
    &__subTitle{
        max-width: 1300px;
        left: 50%;
        transform: translateX(-50%);
        font-size: 25px;
        color: $textColor;
        font-family: 'Poppins', sans-serif;
        margin-bottom: 34px;
        top: 250px;
        z-index: 1;
        position: absolute;
        @media (max-width: 773px){
            top: 330px
        }
    }
    &__inputs{
        max-width: 587px;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
    }
    &__item{
        width: 100%;
        margin: 0;
        margin-bottom: 21px;
        height: 45px;
        border-radius: 10px;
        background: $BlueLite;
        border: 1px solid $GreenColor;
        outline: none;
        padding: 0 10px;
        box-sizing: border-box;
        color: $textColor;
        font-family: 'Poppins', sans-serif;
        &[type=number]::-webkit-inner-spin-button, 
        &[type=number]::-webkit-outer-spin-button { 
            -webkit-appearance: none; 
            margin: 0; 
        }
        &::placeholder{
            color: $GreenColor;
        }
    }

    &__itemL{
        max-width: 311px;
        width: calc(50% - 15px);
    }
    &__text{
        padding: 31px 50px 50px;
        box-sizing: border-box;
        color: $textColor;
        border-radius:0 0 5px 5px;
        background: $YellowLite;
        font-family: 'Poppins', sans-serif;
        @media (max-width:800px) {
            padding: 14px 35px 15px;
        }
    }
}
.disibled{
    opacity: 0.3 !important;
    cursor: context-menu;
}
.banerR__img{
    // z-index: -1;
}
.BusinessRegistration__banerR__imputs{
    background: #FBFAFD;
    // mix-blend-mode: hard-light;
    height: 300px;
}

.BusinessRegistration__banerR__bg{
    // background: #FBFAFD;
    padding: 34px 0 140px ;
    box-sizing: border-box;
    position: relative;
    margin-top: -300px;
}
.BusinessRegistration__block{
    display: flex;
    flex-direction: column;
    width: 100%;
    &__solo{
        display: flex;
        flex-direction: column;
        width: 100%;
        &__top{
            display: flex;
            justify-content: center;
            box-sizing: border-box;
            @media (min-width: 1080px) {
                justify-content: flex-end;
            }
        }
        &__bottom{
            display: flex;
            justify-content: flex-end;
        }
    }
    &__top{
        display: flex;
        justify-content: space-between;
        padding: 20px 30px;
        box-sizing: border-box;
    }
    &__bottom{
        display: flex;
        justify-content: space-between;
        @media (max-width: 1080px) {
            flex-direction: column;
        }
    }
    &__left{
        width: 100%;
        max-width: 100%;
        flex-direction: column;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        @media (min-width: 1080px) {
            align-items: flex-end;
            max-width: calc(50% - 30px);
        }
        &__contain{
            width: 100%;
            height: 100%;
            background: $BlueLite;
            display: flex;
            align-items: flex-start;
            justify-content: flex-end;
            @media (max-width: 1080px) {
                justify-content: center;
            }
        }
        .BusinessRegistration__block__box{
            padding: 39px 30px 39px 30px;
            box-sizing: border-box;
            max-width: 650px;
            width: 100%;
            min-height: 132px;
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
            flex-wrap: wrap;
            white-space: nowrap;
        }
    }
    &__image{
        width: 100%;
        display: none;
        justify-content: center;
        @media (min-width: 1080px) {
            display: flex;
        }
    }
    &__right{
        width: 100%;
        max-width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        @media (min-width: 1080px) {
            align-items: flex-start;
            max-width: calc(50% - 30px);
        }
        &__contain{
            width: 100%;
            height: 100%;
            background: $BlueLite;
            display: flex;
            align-items: flex-start;
            justify-content: flex-start;
            @media (max-width: 1080px) {
                justify-content: center;
            }
        }
        .BusinessRegistration__block__box{
            padding: 39px 55px 39px 30px;
            box-sizing: border-box;
            max-width: 650px;
            width: 100%;
            min-height: 132px;
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
            flex-wrap: wrap;
        }
    }
    &Title__left{
        // max-width: calc(50% - 30px);
        width: 100%;
        margin: 20px 0;
        padding-left: calc(100% - 650px);
        box-sizing: border-box;
        color: $textColor;
        text-align: left;
        font-family: 'Poppins', sans-serif;
        white-space: nowrap;
        &::before{
            content: "";
            margin-left: 30px;
            @media (max-width: 1080px) {
                margin-left: 0px;
            }
        }
        @media (max-width: 1080px) {
            text-align: center;
            padding-left: 0;
        }
    }
    &Title__right{
        max-width: calc(50% - 30px);
        width: 100%;
        margin: 20px 0;
        color: $textColor;
        font-family: 'Poppins', sans-serif;
        box-sizing: border-box;
        white-space: nowrap;
        @media (max-width: 1080px) {
            text-align: center;
        }
    }
    
    &__Text_box{
        width: 650px;
        min-height: 132px;
        padding: 39px 55px 39px 30px;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
    }
    &__Text{
        font-family: 'Poppins', sans-serif;
        margin-bottom: 21px;
    }
}
.BusinessRegistration__banerR_Confirm{
    color: white;
    font-size: 20px;
    padding: 17px 72px;
    box-sizing: border-box;
    border-radius: 5px;
    border: none;
    font-family: 'Poppins', sans-serif;
    background: $GreenColor;
    cursor: pointer;
    margin-left: 50%;
    margin-top: 105px;
    margin-bottom: 50px;
    transform: translateX(-50%);
}

.BusinessRegistration__radios{
    width: 100%;
    display: flex;
    position: relative;
    &L{
        display: flex;
        align-items: center;
        position: relative;
    }
    &T{
        width: 100%;
        display: flex;
        margin-bottom: 31px;
    }
    &__text{
        margin-left: 10px;
        margin-right: 20px;
        font-family: 'Poppins', sans-serif;
        color: $GreyColor;
        text-transform: uppercase;
        white-space: nowrap;
    }
}

.BusinessRegistration__radio {
    display: block;
    position: relative;
    padding-left: 35px;
    box-sizing: border-box;
    left: 20px;
    top: 25px;
    margin-bottom: 12px;
    cursor: pointer;
    position: absolute;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  .checkmarkR {
    position: absolute;
    height: 25px;
    width: 25px;
    cursor: pointer;
    background: #eee;
  }
  .checkmarkI {
    position: absolute;
    height: 25px;
    width: 25px;
    cursor: pointer;
    background-image: url('./../assets/images/tick.png');
  }
  

.BusinessRegistration__radioL{
    position: relative;
    display: flex;
    & input:checked ~ .checkmarkR {
        background-position: center;
        background-size: contain;
        background-image: url('./../assets/images/tick.png');
    }
}
.Member-info__btn{
    bottom: 20px;
    color: white;
    border: none;
    background: #FC8456;
    font-family: 'Poppins', sans-serif;
    padding: 8px 21px;
    box-sizing: border-box;
    border-radius: 5px;
    cursor: pointer;
    font-weight: 600;
}